import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  TextField,
} from "@material-ui/core";

interface IProps {
  addCourse: (cid: string, name: string, code: string, priceCategory: string, season: string) => void;
}

export default function AddCourseModal({ addCourse }: IProps) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [priceCategory, setPriceCategory] = useState("Norm");
  const [season, setSeason] = useState("Semester");

  const close = () => {
    setOpen(false);
    setName("");
    setCode("");
    setPriceCategory("Norm");
    setSeason("Semester");
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Přidat kurz
      </Button>
      <Dialog style={{ overflow: "hidden" }} maxWidth="lg" open={open} onClose={close}>
        <DialogTitle>Přidat nový kurz</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField fullWidth color="primary" value={name} onChange={(e) => setName(e.target.value)} label="Název kurzu" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                color="primary"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                label="Společný suffix kódu kurzu"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <RadioGroup aria-label="priceCategory" row value={priceCategory} onChange={(_, val) => setPriceCategory(val)}>
                  <FormControlLabel value="Norm" control={<Radio />} label="Řádný" />
                  <FormControlLabel value="Cond" control={<Radio />} label="Kondiční" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <RadioGroup
                  aria-label="season"
                  row
                  value={season}
                  onChange={(_, val) => setSeason(val as "Summer" | "Semester")}
                >
                  <FormControlLabel value="Semester" control={<Radio />} label="Semestrální" />
                  <FormControlLabel value="Summer" control={<Radio />} label="Letní" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Zrušit
          </Button>
          <Button
            onClick={() => {
              addCourse("A0Gra", name, `ÚZ-G-${code}`, priceCategory, season);
              close();
            }}
            color="primary"
          >
            Přidat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
