export const scrollToRefObject = (ref: any) => window.scrollTo(0, ref.current.offsetTop); //TODO ref type

export const round = (x: number, dec: number) => Math.round(x * Math.pow(10, dec)) / Math.pow(10, dec);

export const cutString = (str: string, maxLength: number) =>
  str.length > maxLength + 4 ? str.substring(0, maxLength) + " ..." : str;

export const separateThousands = (x: string | number | undefined) =>
  x === undefined ? "" : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const addDecimals = (x: string, decimals: number) => {
  const curDecimals = x.includes(".") ? x.length - x.indexOf(".") - 1 : 0;
  if (curDecimals === 0) x += ".";
  return x + "0".repeat(Math.max(decimals - curDecimals, 0));
};
