import React, { useEffect, useState } from "react";
import {
  Checkbox,
  createStyles,
  Fab,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import { Course, Courses } from "../../Types/courses";
import { useLayout } from "../../Layout/LayoutContext";
import { api } from "../../Utils/ApiService";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { buildId, formatToAdminCourses } from "../../Utils/CoursesReformatter";
import AddCourseModal from "./AddCourseModal";
import csLocale from "date-fns/locale/cs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
    },
    saveButton: {
      position: "fixed",
      bottom: 32,
      right: 16,
    },
  })
);

export default function DashBoard() {
  const classes = useStyles();
  const [courses, setCourses] = useState<Courses | undefined>(undefined);
  const [tab, setTab] = useState(0);
  const layout = useLayout();

  useEffect(() => {
    layout.setIsLoading(true);
    api.get(
      "/fileContent",
      { location: "config", file: "courses.json" },
      {
        success: (c) => {
          setCourses(JSON.parse(c));
          layout.setIsLoading(false);
        },
        error: () => layout.error("Při načítání kurzů došlo k chybě"),
      }
    );
    //eslint-disable-next-line
  }, []);

  const formattedCourses =
    (courses && formatToAdminCourses(courses).sort((a, b) => (a.codeSuffix < b.codeSuffix ? 1 : a.codeSuffix === b.codeSuffix ? 0 : -1))) || [];

  const saveChanges = () =>
    api.post("saveTextFile", { location: "config", file: "courses.json" }, courses, {
      success: () => layout.success("Změny byly úspěšně uloženy"),
      error: () => layout.error("Při ukládání došlo k chybě"),
    });

  const updateCourse = (cid: string, prop: keyof Course, code: string, newVal: any) => {
    if (courses !== undefined) {
      const newCourses = { ...courses };
      newCourses.courses = { ...courses.courses };
      newCourses.courses[cid] = { ...courses.courses[cid] };
      newCourses.courses[cid].courses = [...newCourses.courses[cid].courses];
      const index = courses.courses[cid].courses
        .map((c, i) => {
          return { course: c, index: i };
        })
        .filter((c) => c.course.code === code)[0].index;
      // @ts-ignore
      newCourses.courses[cid].courses[index][prop] = newVal;
      setCourses(newCourses);
    }
  };

  const addCourse = (cid: string, name: string, code: string, priceCategory: "Norm" | "Cond", season: "Summer" | "Semester") => {
    if (courses !== undefined) {
      const newCourses = { ...courses };
      newCourses.courses = { ...courses.courses };
      newCourses.courses[cid] = {
        ...courses.courses[cid],
        courses: [
          ...newCourses.courses[cid].courses,
          {
            name: name,
            code: code,
            dates: "",
            times: "",
            start: new Date(),
            priceCategory: priceCategory,
            season: season,
          },
        ],
      };
      setCourses(newCourses);
    }
  };

  const deleteCourse = (cid: string, code: string, counter: number) => {
    if (courses !== undefined) {
      // if (formattedCourses[tab]?.courses.filter((cc) => cc.level === cid && cc.counter === counter + 1).length > 0) {
      //   alert("Tento kurz nelze smazat");
      //   return;
      // }
      const newCourses = { ...courses };
      newCourses.courses = { ...courses.courses };
      newCourses.courses[cid] = { ...courses.courses[cid] };
      const index = courses.courses[cid].courses
        .map((c, i) => {
          return { course: c, index: i };
        })
        .filter((c) => c.course.code === code)[0].index;
      newCourses.courses[cid].courses = courses.courses[cid].courses.filter((_, i) => i !== index);
      setCourses(newCourses);
    }
  };

  // const coursesWithCounter = (
  //   courses ? Object.keys(courses.courses).map((c) => ({ c: c, counter: formattedCourses[tab]?.selectedLevels.filter((cc) => cc === c).length || 1 })) : []
  // )
  //   .map(({ c, counter }) => Array.from(new Array(counter)).map((_, ii) => ({ c: c, counter: ii + 1 })))
  //   .flat(1);

  const coursesWithCounter = (
    courses
      ? Object.keys(courses.courses).map((c) => ({
          c: c,
          counter:
            formattedCourses[tab]?.selectedLevels.filter((cc) => cc === c).length === 0
              ? [1]
              : formattedCourses[tab]?.courses.filter((cc) => cc.level === c).map((cc) => cc.counter),
        }))
      : []
  )
    .map(({ c, counter }) => counter?.map((cnt) => ({ c: c, counter: cnt })) || [])
    .flat(1);

  return (
    <div className={classes.root}>
      {courses && (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>
          <>
            <div style={{ display: "flex" }}>
              <Tabs value={tab} onChange={(_, i) => setTab(i)}>
                {formattedCourses.map((k) => (
                  <Tab label={k.codeSuffix} key={k.codeSuffix} />
                ))}
              </Tabs>
              {
                //@ts-ignore
                <AddCourseModal addCourse={addCourse} />
              }
            </div>
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item>
                <br />
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;XX-X-{formattedCourses[tab]?.codeSuffix}</Typography>
              </Grid>
              <Grid item>
                <TextField
                  value={formattedCourses[tab]?.name}
                  label="kurz - rozsah + intenzita"
                  fullWidth
                  color="primary"
                  onChange={(e) =>
                    formattedCourses[tab]?.selectedLevels.forEach((c) =>
                      updateCourse(c, "name", buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix), e.target.value)
                    )
                  }
                />
              </Grid>
              <Grid item>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <FormControl>
                  <RadioGroup
                    aria-label="priceCategory"
                    row
                    value={formattedCourses[tab]?.priceCategory}
                    onChange={(_, val) =>
                      formattedCourses[tab]?.selectedLevels.forEach((c) =>
                        updateCourse(c, "priceCategory", buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix), val)
                      )
                    }
                  >
                    <FormControlLabel value="Norm" control={<Radio />} label="Řádný" />
                    <FormControlLabel value="Cond" control={<Radio />} label="Kondiční" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <FormControl>
                  <RadioGroup
                    aria-label="season"
                    row
                    value={formattedCourses[tab]?.season}
                    onChange={(_, val) =>
                      formattedCourses[tab]?.selectedLevels.forEach((c) =>
                        updateCourse(c, "season", buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix), val)
                      )
                    }
                  >
                    <FormControlLabel value="Semester" control={<Radio />} label="Semestrální" />
                    <FormControlLabel value="Summer" control={<Radio />} label="Letní" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <br />
            <Typography variant="h6">&nbsp;&nbsp;&nbsp;&nbsp;kurz - úroveň + obsah</Typography>
            <Table>
              <TableBody>
                {coursesWithCounter.map(({ c, counter }, i) => (
                  <TableRow key={`${c}-${i}`}>
                    <TableCell
                      onClick={() => {
                        if (formattedCourses[tab]?.selectedLevels.includes(c)) {
                          layout.deleteConfirm(
                            "Smazání kurzu",
                            "Opravdu chcete smazat tento kurz?",
                            () => deleteCourse(c, formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].code, counter)
                            // deleteCourse(c, buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix, counter), counter)
                          );
                        } else {
                          addCourse(
                            c,
                            formattedCourses[tab]?.name,
                            buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix, counter),
                            formattedCourses[tab]?.priceCategory,
                            formattedCourses[tab]?.season
                          );
                        }
                      }}
                    >
                      <Checkbox color="primary" checked={formattedCourses[tab]?.selectedLevels.includes(c)} />
                      {courses.courses[c].title} {courses.courses[c].type}
                    </TableCell>
                    {formattedCourses[tab]?.selectedLevels.includes(c) ? (
                      <>
                        <TableCell>
                          <Typography>{formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].code}</Typography>
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Termín"
                            fullWidth
                            value={formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].dates}
                            onChange={(e) =>
                              updateCourse(
                                c,
                                "dates",
                                // buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix, counter),
                                formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].code,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Rozvrh"
                            fullWidth
                            value={formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].times}
                            onChange={(e) =>
                              updateCourse(
                                c,
                                "times",
                                // buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix, counter),
                                formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].code,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <KeyboardDateTimePicker
                            ampm={false}
                            disableToolbar
                            value={formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].startDate}
                            onChange={(d) =>
                              updateCourse(
                                c,
                                "start",
                                // buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix, counter),
                                formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].code,
                                d
                              )
                            }
                            variant="inline"
                            format="dd.MM.yyyy HH:mm"
                            margin="normal"
                            label="První lekce"
                          />
                        </TableCell>
                        <TableCell
                          style={{ maxWidth: 156 }}
                          onClick={() =>
                            updateCourse(
                              c,
                              "noSignUp",
                              // buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix, counter),
                              formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].code,
                              !(formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].noSignUp === true)
                            )
                          }
                        >
                          <Checkbox
                            color="primary"
                            checked={formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter)[0].noSignUp === true}
                          />
                          Zakázat přihlašování
                        </TableCell>
                        <TableCell>
                          {formattedCourses[tab]?.courses.filter((cc) => cc.level === c && cc.counter === counter + 1).length === 0 && (
                            <Button
                              onClick={() => {
                                addCourse(
                                  c,
                                  formattedCourses[tab]?.name,
                                  buildId(courses.courses[c].title, courses.courses[c].type, formattedCourses[tab]?.codeSuffix, counter + 1),
                                  formattedCourses[tab]?.priceCategory,
                                  formattedCourses[tab]?.season
                                );
                              }}
                            >
                              +
                            </Button>
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <TableCell colSpan={6} />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </MuiPickersUtilsProvider>
      )}
      <Fab color="primary" variant="extended" className={classes.saveButton} onClick={saveChanges}>
        Uložit změny
      </Fab>
    </div>
  );
}
