import React, { useState, createContext, useContext } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Layout from "./Layout";
import DeleteConfirmModal from "./DeleteConfirmModal";
import ConfirmModal from "./ConfirmModal";
import SimpleTextFormModal from "./SimpleTextFormModal";

export const LayoutContext = createContext({
  success: (message: string) => {},
  error: (message: string) => {},
  warning: (message: string) => {},
  info: (message: string) => {},
  setIsLoading: (isLoading: boolean) => {},
  deleteConfirm: (title: string, message: string, onDelete: () => void) => {},
  confirm: (title: string, message: string, onConfirm: () => void) => {},
  simpleTextForm: (title: string, message: string, label: string, onSave: (value: string) => void) => {},
  isLoading: false,
});

export enum AlertSeverity {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export function LayoutProvider(props: { children?: React.ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState(AlertSeverity.SUCCESS);
  const [deleteConfirmData, setDeleteConfirmData] = useState({
    opened: false,
    message: "",
    title: "",
    onDelete: () => {},
  });
  const [confirmData, setConfirmData] = useState({
    opened: false,
    message: "",
    title: "",
    onConfirm: () => {},
  });
  const [simpleTextFormData, setSimpleTextFormData] = useState({
    opened: false,
    save: (value: string) => {},
    message: "",
    title: "",
    label: "",
  });

  const success = (message: string) => {
    setAlertSeverity(AlertSeverity.SUCCESS);
    setAlertMessage(message);
  };
  const error = (message: string) => {
    setAlertSeverity(AlertSeverity.ERROR);
    setAlertMessage(message);
  };
  const warning = (message: string) => {
    setAlertSeverity(AlertSeverity.WARNING);
    setAlertMessage(message);
  };
  const info = (message: string) => {
    setAlertSeverity(AlertSeverity.INFO);
    setAlertMessage(message);
  };
  const deleteConfirm = (title: string, message: string, onDelete: () => void) => {
    setDeleteConfirmData({
      message: message,
      onDelete: onDelete,
      opened: true,
      title: title,
    });
  };
  const confirm = (title: string, message: string, onConfirm: () => void) => {
    setConfirmData({
      message: message,
      onConfirm: onConfirm,
      opened: true,
      title: title,
    });
  };
  const simpleTextForm = (title: string, message: string, label: string, onSave: (value: string) => void) => {
    setSimpleTextFormData({
      opened: true,
      save: onSave,
      message: message,
      title: title,
      label: label,
    });
  };

  return (
    <LayoutContext.Provider
      value={{
        success: success,
        error: error,
        warning: warning,
        info: info,
        setIsLoading: setLoading,
        isLoading: loading,
        deleteConfirm: deleteConfirm,
        confirm: confirm,
        simpleTextForm: simpleTextForm,
      }}
    >
      <Layout loading={loading}>
        {props.children}
        <Snackbar
          open={Boolean(alertMessage)}
          onClose={() => setAlertMessage("")}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setAlertMessage("")} severity={alertSeverity}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <DeleteConfirmModal
          opened={deleteConfirmData.opened}
          close={() => setDeleteConfirmData({ ...deleteConfirmData, opened: false })}
          confirm={deleteConfirmData.onDelete}
          message={deleteConfirmData.message}
          title={deleteConfirmData.title}
        />
        <ConfirmModal
          opened={confirmData.opened}
          close={() => setConfirmData({ ...confirmData, opened: false })}
          confirm={confirmData.onConfirm}
          message={confirmData.message}
          title={confirmData.title}
        />
        <SimpleTextFormModal
          close={() => setSimpleTextFormData({ ...simpleTextFormData, opened: false })}
          label={simpleTextFormData.label}
          message={simpleTextFormData.message}
          opened={simpleTextFormData.opened}
          save={simpleTextFormData.save}
          title={simpleTextFormData.title}
        />
      </Layout>
    </LayoutContext.Provider>
  );
}

export const useLayout = () => useContext(LayoutContext);
