import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";

interface IProps {
  opened: boolean;
  close: () => void;
  confirm: () => void;
  message: string;
  title: string;
}

export default function DeleteConfirmModal({ close, confirm, message, opened, title }: IProps) {
  return (
    <Dialog open={opened} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary">
          Zrušit
        </Button>
        <Button
          onClick={() => {
            confirm();
            close();
          }}
          color="primary"
        >
          Smazat
        </Button>
      </DialogActions>
    </Dialog>
  );
}
