import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { createStyles, createTheme, LinearProgress, makeStyles, MuiThemeProvider, Theme } from "@material-ui/core";
import NotAuthenticated from "../Pages/NotAuthenticated/NotAuthenticated";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagesRoot: {
      padding: 0,
      margin: 0,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

const themeStoredCode: string = localStorage.getItem("theme") || "light";

const getTheme = (theme: "dark" | "light") => {
  return createTheme({ palette: { type: theme } });
};

export default function Layout({ children, loading }: { children?: React.ReactNode; loading: boolean }) {
  const expectedToken = "DNSKJATD67892897DNJ7D8ACHJ98";

  const classes = useStyles();
  const [theme, setTheme] = useState<"dark" | "light">(themeStoredCode as "dark" | "light");
  const [isAuthenticated, setAuthenticated] = useState(sessionStorage.getItem("authenticatedToken") === expectedToken);

  useEffect(() => {
    if (themeStoredCode === "dark") changeAppTheme("dark");
  }, []);

  const login = (token: string) => {
    setAuthenticated(token === expectedToken);
    sessionStorage.setItem("authenticatedToken", token);
  };

  const logout = () => {
    setAuthenticated(false);
    sessionStorage.removeItem("authenticatedToken");
  };

  const changeAppTheme = (theme: "dark" | "light") => {
    document.body.style.backgroundColor = "";
    document.body.style.color = "";

    if (theme === "dark") {
      document.body.style.backgroundColor = "#212121";
      document.body.style.color = "#fafafa";
    }

    setTheme(theme);
    localStorage.setItem("theme", theme);
  };

  return (
    <MuiThemeProvider theme={getTheme(theme)}>
      <Header isAuthenticated={isAuthenticated} logout={logout} />
      <>
        <LinearProgress
          style={{ position: "fixed", top: 73, left: -16, height: 4, width: "calc(100% - 32px)" }}
          hidden={!loading}
        />
        {(isAuthenticated && (
          <>
            <div className={classes.pagesRoot}>
              <br />
              <br />
              {children}
            </div>
          </>
        )) || <NotAuthenticated login={login} />}
      </>
      <Footer />
    </MuiThemeProvider>
  );
}
