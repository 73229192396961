import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route, RouteComponentProps } from "react-router-dom";
import Dashboard from "./Pages/Home/Dashboard";
import { LayoutProvider } from "./Layout/LayoutContext";
import Prices from "./Pages/PricesAndCourses/Prices";
import Site from "./Pages/Site/Site";
import Users from "./Pages/Users/Users";
interface MatchParams {
  name: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

ReactDOM.render(
  <BrowserRouter>
    <LayoutProvider>
      <Switch>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Route path="/prices" exact>
          <Prices />
        </Route>
        <Route path="/users" exact>
          <Users />
        </Route>
        <Route path="/site/:name" render={({ match }: MatchProps) => <Site fileName={match.params.name} />} />
      </Switch>
    </LayoutProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
