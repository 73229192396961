import { AppBar, Button, Toolbar } from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      padding: 4,
      paddingRight: 16,
      display: "flex",
      flexDirection: "row",
    },
    flexGrow: {
      flexGrow: 1,
    },
    loginButton: {
      color: "white",
    },
  })
);

interface IProps {
  isAuthenticated: boolean;
  logout: () => void;
}

export default function Header({ isAuthenticated, logout }: IProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="relative" className={classes.bar}>
      <Toolbar className={classes.flexGrow}>
        <Button style={{ color: "white" }} onClick={() => history.push("/prices")}>
          Ceny a typy kurzů
        </Button>
        <Button style={{ color: "white" }} onClick={() => history.push("/")}>
          Plán kurzů
        </Button>
        <Button style={{ color: "white" }} onClick={() => history.push("/users")}>
          Přihlášky a uživatelé
        </Button>
        <Button style={{ color: "white" }} onClick={() => history.push("/site/conditions")}>
          Obchodní podmínky
        </Button>
        <Button style={{ color: "white" }} onClick={() => history.push("/site/privacyPolicy")}>
          Zpracování osobních údajů
        </Button>
      </Toolbar>
      {isAuthenticated && (
        <Button className={classes.loginButton} onClick={logout}>
          Odhlásit se
        </Button>
      )}
    </AppBar>
  );
}
