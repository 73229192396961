import React, { useState } from "react";
import { Button, Container, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { api } from "../../Utils/ApiService";
import { useLayout } from "../../Layout/LayoutContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  })
);

interface IProps {
  login: (token: string) => void;
}

export default function NotAuthenticated({ login }: IProps) {
  const classes = useStyles();
  const [pwd, setPwd] = useState("");
  const layout = useLayout();

  const loginClick = () => {
    api.post("/adminLogin", { password: pwd }, null, {
      success: (res: string) => {
        if (res === "Unauthorized") {
          layout.error("Zadané heslo není správné.");
        } else {
          login(res);
        }
        setPwd("");
      },
      error: () => layout.error("Při pokusu o přihlášení došlo k chybě."),
    });
  };

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Typography variant="h6" align="center">
        Přihlášení
      </Typography>
      <br />
      <br />
      <TextField
        type="password"
        fullWidth
        value={pwd}
        onChange={(e) => setPwd(e.target.value)}
        label="Administrátorské heslo"
      />
      <br />
      <br />
      <Button color="primary" variant="contained" onClick={loginClick}>
        Přihlásit se
      </Button>
    </Container>
  );
}
