import React, { useEffect, useRef, useState } from "react";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { api } from "../../Utils/ApiService";
import { useLayout } from "../../Layout/LayoutContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

interface IProps {
  fileName: string;
}

export default function Site({ fileName }: IProps) {
  const classes = useStyles();
  const [text, setText] = useState("");
  const layout = useLayout();

  const editorRef = useRef(null);

  useEffect(() => {
    api.get(
      "fileContent",
      {
        location: "config",
        file: `${fileName}.html`,
      },
      {
        success: setText,
        error: () => layout.error("Při načítání došlo k chybě"),
      }
    );
    // eslint-disable-next-line
  }, [fileName]);

  const save = () =>
    api.postNoJSON(
      "saveTextFile",
      {
        location: "config",
        file: `${fileName}.html`,
      },
      //@ts-ignore
      editorRef.current.getContent(),
      {
        success: () => layout.success("Změny byly uloženy"),
        error: () => layout.error("Při ukládání došlo k chybě"),
      }
    );

  return (
    <div className={classes.root}>
      {text.length > 0 && (
        <>
          <Editor
            // @ts-ignore
            onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey="tuzucg1cthoqcneehn6f0sk09il2oynsqvqdi9hezasq63pw"
            init={{
              plugins: [
                "advlist autolink lists link",
                "charmap print preview anchor help",
                "searchreplace visualblocks code",
                "insertdatetime media table paste wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
              automatic_uploads: true,
              height: 500,
              images_reuse_filename: true,
            }}
            initialValue={text}
          />
          <Button color="primary" onClick={() => save()}>
            Uložit změny
          </Button>
        </>
      )}
    </div>
  );
}
