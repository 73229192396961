import { adminCourses, Courses } from "../Types/courses";

export const formatToAdminCourses = (rawCourses: Courses) => {
  const allCoursesInfo = Object.keys(rawCourses.courses)
    .map((lvl) =>
      rawCourses.courses[lvl].courses.map((c) => ({
        ...c,
        lvl: lvl,
      }))
    )
    .flat();

  const coursesGroupedByName: {
    [index: string]: {
      lvl: string;
      name: string;
      code: string;
      dates: string;
      times: string;
      start: Date;
      noSignUp?: boolean;
      priceCategory: "Norm" | "Cond";
      season: "Summer" | "Semester";
    }[];
  } = {};

  allCoursesInfo.forEach((c) => {
    if (!(c.name in coursesGroupedByName)) coursesGroupedByName[c.name] = [];
    coursesGroupedByName[c.name].push(c);
  });

  return Object.keys(coursesGroupedByName).map((name) => {
    return {
      codeSuffix: coursesGroupedByName[name][0].code.substring(5),
      courses: coursesGroupedByName[name].map((c) => {
        return {
          dates: c.dates,
          level: c.lvl,
          startDate: c.start,
          times: c.times,
          noSignUp: c.noSignUp,
          counter: /[0-9]/.test(c.code[4]) ? parseInt(c.code[4]) : 1,
          code: c.code,
        };
      }),
      name: name,
      priceCategory: coursesGroupedByName[name][0].priceCategory,
      season: coursesGroupedByName[name][0].season,
      selectedLevels: coursesGroupedByName[name].map((c) => c.lvl),
    } as adminCourses;
  });
};

export const buildId = (levelName: string, level: string, suffix: string, counter?: number) =>
  `${levelName
    .split(" ")
    .map((l) => l[0])
    .join("")
    .toLocaleUpperCase()}-${level[0].toLocaleUpperCase()}${counter === undefined || counter <= 1 ? "" : counter.toString()}-${suffix}`;
