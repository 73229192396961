import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Courses, Price } from "../../Types/courses";
import { useLayout } from "../../Layout/LayoutContext";
import { api } from "../../Utils/ApiService";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
    },
    saveButton: {
      position: "fixed",
      bottom: 32,
      right: 16,
    },
  })
);

export default function Prices() {
  const classes = useStyles();
  const [courses, setCourses] = useState<Courses | undefined>(undefined);
  const [tab, setTab] = useState("semester");
  const layout = useLayout();

  useEffect(() => {
    layout.setIsLoading(true);
    api.get(
      "/fileContent",
      { location: "config", file: "courses.json" },
      {
        success: (c) => {
          setCourses(JSON.parse(c));
          layout.setIsLoading(false);
        },
        error: () => layout.error("Při načítání kurzů došlo k chybě"),
      }
    );
    //eslint-disable-next-line
  }, []);

  const saveChanges = () =>
    api.post("saveTextFile", { location: "config", file: "courses.json" }, courses, {
      success: () => layout.success("Změny byly úspěšně uloženy"),
      error: () => layout.error("Při ukládání došlo k chybě"),
    });

  const changePrice = (category: string, priceType: keyof Price, newPrice: string) => {
    if (courses !== undefined) {
      const newCourses = { ...courses };
      newCourses.prices = { ...courses.prices };
      newCourses.prices[category] = { ...newCourses.prices[category] };
      newCourses.prices[category][priceType] = parseInt(newPrice || "0");
      setCourses(newCourses);
    }
  };

  const changeIntroDesc = (category: string, newVal: string) => {
    if (courses !== undefined) {
      const newCourses = { ...courses };
      newCourses.courseIntro = { ...courses.courseIntro };
      newCourses.courseIntro[category] = { ...newCourses.courseIntro[category], description: newVal };
      setCourses(newCourses);
    }
  };

  const addIntroCourse = (category: string) => {
    if (courses !== undefined) {
      const newCourses = { ...courses };
      newCourses.courseIntro = { ...courses.courseIntro };
      newCourses.courseIntro[category] = { ...courses.courseIntro[category] };
      newCourses.courseIntro[category].courses = [
        ...courses.courseIntro[category].courses,
        {
          dates: "",
          dotation: 0,
          label: "",
        },
      ];
      setCourses(newCourses);
    }
  };

  const updateIntroCourse = (category: string, prop: "label" | "dates" | "dotation", index: number, newVal: any) => {
    if (courses !== undefined) {
      const newCourses = { ...courses };
      newCourses.courseIntro = { ...courses.courseIntro };
      newCourses.courseIntro[category] = { ...courses.courseIntro[category] };
      newCourses.courseIntro[category].courses = [...courses.courseIntro[category].courses];
      // @ts-ignore
      newCourses.courseIntro[category].courses[index][prop] = newVal;
      setCourses(newCourses);
    }
  };

  const deleteIntroCourse = (category: string, index: number) => {
    if (courses !== undefined) {
      const newCourses = { ...courses };
      newCourses.courseIntro = { ...courses.courseIntro };
      newCourses.courseIntro[category] = { ...courses.courseIntro[category] };
      newCourses.courseIntro[category].courses = courses.courseIntro[category].courses.filter((_, i) => i !== index);
      setCourses(newCourses);
    }
  };

  return (
    <div className={classes.root}>
      {courses && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Ceny</Typography>
            <br />
            <TextField
              label="Sazba - firemní výuka min. Kč / vh"
              fullWidth
              value={courses.companyPrices || ""}
              onChange={(e) => setCourses({ ...courses, companyPrices: e.target.value })}
            />

            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={148}>&nbsp;</TableCell>
                  <TableCell width={120}>&nbsp;</TableCell>
                  <TableCell width={120}>&nbsp;</TableCell>
                  <TableCell width={120}>&nbsp;</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>gramatický řádný</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="standardní cena"
                      fullWidth
                      value={courses?.prices["GRNorm"].normal}
                      onChange={(e) => changePrice("GRNorm", "normal", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 2T+"
                      fullWidth
                      value={courses?.prices["GRNorm"].twoWeeks}
                      onChange={(e) => changePrice("GRNorm", "twoWeeks", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 4T+"
                      fullWidth
                      value={courses?.prices["GRNorm"].fourWeeks}
                      onChange={(e) => changePrice("GRNorm", "fourWeeks", e.target.value)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>gramarický kondiční</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="standardní cena"
                      fullWidth
                      value={courses?.prices["GRCond"].normal}
                      onChange={(e) => changePrice("GRCond", "normal", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 2T+"
                      fullWidth
                      value={courses?.prices["GRCond"].twoWeeks}
                      onChange={(e) => changePrice("GRCond", "twoWeeks", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 4T+"
                      fullWidth
                      value={courses?.prices["GRCond"].fourWeeks}
                      onChange={(e) => changePrice("GRCond", "fourWeeks", e.target.value)}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>konverzační řádný</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="standardní cena"
                      fullWidth
                      value={courses?.prices["CONorm"].normal}
                      onChange={(e) => changePrice("CONorm", "normal", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 2T+"
                      fullWidth
                      value={courses?.prices["CONorm"].twoWeeks}
                      onChange={(e) => changePrice("CONorm", "twoWeeks", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 4T+"
                      fullWidth
                      value={courses?.prices["CONorm"].fourWeeks}
                      onChange={(e) => changePrice("CONorm", "fourWeeks", e.target.value)}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>konverzační kondiční</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="standardní cena"
                      fullWidth
                      value={courses?.prices["COCond"].normal}
                      onChange={(e) => changePrice("COCond", "normal", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 2T+"
                      fullWidth
                      value={courses?.prices["COCond"].twoWeeks}
                      onChange={(e) => changePrice("COCond", "twoWeeks", e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="zvýhodněná 4T+"
                      fullWidth
                      value={courses?.prices["COCond"].fourWeeks}
                      onChange={(e) => changePrice("COCond", "fourWeeks", e.target.value)}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <br />
            <br />
            <Typography variant="h4">Popis kurzů</Typography>
            <br />
            <Tabs value={tab} onChange={(_, val) => setTab(val)} indicatorColor="primary" textColor="primary" centered>
              <Tab label="Semestrální kurzy" value="semester" />
              <Tab label="Letní kurzy" value="holiday" />
            </Tabs>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Řádné kurzy</Typography>
                <br />
                <TextField
                  multiline
                  variant="outlined"
                  rows={4}
                  label="Popis"
                  fullWidth
                  value={courses.courseIntro[tab === "semester" ? "SEMNorm" : "HOLNorm"].description || ""}
                  onChange={(e) => changeIntroDesc(tab === "semester" ? "SEMNorm" : "HOLNorm", e.target.value)}
                />
                <Table>
                  <TableBody>
                    {courses.courseIntro[tab === "semester" ? "SEMNorm" : "HOLNorm"].courses?.map((c, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ maxWidth: 100 }}>
                          <TextField
                            label="Rozsah a intenzita"
                            fullWidth
                            value={c.label}
                            onChange={(e) =>
                              updateIntroCourse(tab === "semester" ? "SEMNorm" : "HOLNorm", "label", i, e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Rozvrh"
                            fullWidth
                            value={c.dates}
                            onChange={(e) =>
                              updateIntroCourse(tab === "semester" ? "SEMNorm" : "HOLNorm", "dates", i, e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell style={{ maxWidth: 20 }}>
                          <TextField
                            label="Počet vh"
                            value={c.dotation}
                            onChange={(e) =>
                              updateIntroCourse(
                                tab === "semester" ? "SEMNorm" : "HOLNorm",
                                "dotation",
                                i,
                                parseInt(e.target.value || "0")
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              layout.confirm("Opravdu chcete smazat tento kurz?", "Kurz bude trvale smazán", () =>
                                deleteIntroCourse(tab === "semester" ? "SEMNorm" : "HOLNorm", i)
                              )
                            }
                          >
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Button color="primary" onClick={() => addIntroCourse(tab === "semester" ? "SEMNorm" : "HOLNorm")}>
                  Přidat typ kurzu
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Kondiční kurzy</Typography>
                <br />
                <TextField
                  multiline
                  variant="outlined"
                  rows={4}
                  label="Popis"
                  fullWidth
                  value={courses.courseIntro[tab === "semester" ? "SEMCond" : "HOLCond"].description || ""}
                  onChange={(e) => changeIntroDesc(tab === "semester" ? "SEMCond" : "HOLCond", e.target.value)}
                />
                <Table>
                  <TableBody>
                    {courses.courseIntro[tab === "semester" ? "SEMCond" : "HOLCond"].courses?.map((c, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ maxWidth: 100 }}>
                          <TextField
                            label="Rozsah a intenzita"
                            fullWidth
                            value={c.label}
                            onChange={(e) =>
                              updateIntroCourse(tab === "semester" ? "SEMCond" : "HOLCond", "label", i, e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Rozvrh"
                            fullWidth
                            value={c.dates}
                            onChange={(e) =>
                              updateIntroCourse(tab === "semester" ? "SEMCond" : "HOLCond", "dates", i, e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell style={{ maxWidth: 20 }}>
                          <TextField
                            label="Počet vh"
                            value={c.dotation}
                            onChange={(e) =>
                              updateIntroCourse(
                                tab === "semester" ? "SEMCond" : "HOLCond",
                                "dotation",
                                i,
                                parseInt(e.target.value || "0")
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              layout.confirm("Opravdu chcete smazat tento kurz?", "Kurz bude trvale smazán", () =>
                                deleteIntroCourse(tab === "semester" ? "SEMCond" : "HOLCond", i)
                              )
                            }
                          >
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Button color="primary" onClick={() => addIntroCourse(tab === "semester" ? "SEMCond" : "HOLCond")}>
                  Přidat typ kurzu
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Fab color="primary" variant="extended" className={classes.saveButton} onClick={saveChanges}>
        Uložit změny
      </Fab>
    </div>
  );
}
