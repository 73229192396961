import { Courses, User } from "../Types/courses";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { addDecimals, round, separateThousands } from "./Common";

export async function generateInvoice(usr: User, courses: Courses) {
  const workbook = new Excel.Workbook();

  const worksheet = workbook.addWorksheet("Faktura");

  const coursesContainer = Object.keys(courses?.courses)
    .map((c) => courses?.courses[c])
    .filter((c) => c?.courses.filter((cc) => cc.code === usr.course).length > 0)[0];

  const course = coursesContainer.courses.filter((c) => c.code === usr.course)[0];

  const courseName = `${coursesContainer.title} ${coursesContainer.type}, ${course.name} kurz angličtiny, ${course.dates}`;

  setData(usr, courseName, worksheet);
  setMerges(worksheet);
  setSizes(worksheet);
  formatFonts(worksheet);
  setAlignment(worksheet);
  setBorders(worksheet);

  worksheet.pageSetup.margins = { top: 0.3, bottom: 0.3, left: 0.3, right: 0.1, footer: 0, header: 0 };

  toDataUrl("/logo.png", (x) => {
    worksheet.addImage(workbook.addImage({ base64: x as string, extension: "png" }), {
      tl: { col: 6, row: 39 },
      ext: { width: 138.88, height: 36.36 },
    });
    workbook.xlsx.writeBuffer().then((buf) => saveAs(new Blob([buf]), "Faktura.xlsx"));
  });
}

const setData = (usr: User, courseName: string, worksheet: Excel.Worksheet) => {
  worksheet.addRow(["FAKTURA - DAŇOVÝ DOKLAD Č. " + usr.variableSymbol, "", "", "", "", "! NEPLAŤTE, JIŽ UHRAZENO !", "", ""]);
  worksheet.addRow([]);
  worksheet.addRow(["Dodavatel:", "", "", "", "Odběratel:", "", "", ""]);
  worksheet.addRow([
    "Englishfit",
    "",
    "",
    "",
    usr.differentBillingAddress ? usr.billingCompanyName : usr.companyName,
    "",
    "",
    "",
  ]);
  worksheet.addRow([
    "Mgr. Marek Chocholatý",
    "",
    "",
    "",
    usr.differentBillingAddress ? `${usr.billingName} ${usr.billingSurname}` : `${usr.name} ${usr.surname}`,
    "",
    "",
    "",
  ]);
  worksheet.addRow([
    "Horova 1859",
    "",
    "",
    "",
    usr.differentBillingAddress ? `${usr.billingStreet} ${usr.billingHouseNumber}` : `${usr.street} ${usr.houseNumber}`,
    "",
    "",
    "",
  ]);
  worksheet.addRow([
    "666 03 Tišnov",
    "",
    "",
    "",
    usr.differentBillingAddress ? `${usr.billingZip} ${usr.billingCity}` : `${usr.zip} ${usr.city}`,
    "",
    "",
    "",
  ]);
  worksheet.addRow(["Česká republika", "", "", "", "Česká republika", "", "", ""]);
  worksheet.addRow([
    "IČ: 47345128",
    "",
    "",
    "",
    `IČ: ${usr.differentBillingAddress ? usr.billingCompanyNumber : usr.companyNumber}`,
    "",
    "",
    "",
  ]);
  worksheet.addRow([
    "DIČ: CZ6903264588",
    "",
    "",
    "",
    `DIČ: ${usr.differentBillingAddress ? usr.billingTaxId : usr.taxId}`,
    "",
    "",
    "",
  ]);
  worksheet.addRow([
    "Podnikající fyzická osoba zapsaná\nv Živnostenském rejstříku u MěÚ Tišnov\nod 7.12.2007 pod evidenčním číslem 370204-9007\nje plátcem DPH.",
  ]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);

  worksheet.addRow(["Platební podmínky:", "", "", "", "Forma úhrady:", "", "", "převodem"]);
  worksheet.addRow([
    "Bankovní spojení:",
    "",
    "",
    "8436270841/5500",
    "Datum vystavení:",
    "",
    "",
    new Date().toLocaleDateString(),
  ]);
  worksheet.addRow(["Variabilní symbol:", "", "", usr.variableSymbol, "Datum splatnosti:", "", "uhrazeno zálohou", ""]);
  worksheet.addRow([
    "Konstantní symbol:",
    "",
    "",
    "0308",
    "Datum zdanitelného plnění:",
    "",
    "",
    new Date().toLocaleDateString(),
  ]);

  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow(["Fakturuji Vám objednané služby:", ""]);
  worksheet.addRow(["popis služby - název kurzu, termín, rozsah", "", "", "", "", "", "", "cena celkem s DPH"]);
  worksheet.addRow([courseName, "", "", "", "", "", "", `${usr.price} Kč`]);
  worksheet.addRow(["Uhrazené zálohy", "", "", "", "", "", "", `-${usr.price} Kč`]);
  worksheet.addRow([]);
  worksheet.addRow(["Ceklem k úhradě", "", "", "", "", "", "", "0 Kč"]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([
    "Rekapitulace DPH:",
    "",
    "",
    "",
    "",
    "Celkem bez DPH:",
    "",
    `${addDecimals(separateThousands(round(usr.price / 1.21, 2)), 2)} Kč`,
  ]);
  worksheet.addRow([
    "",
    "",
    "",
    "",
    "",
    "DPH 21%:",
    "",
    `${addDecimals(separateThousands(round(usr.price - round(usr.price / 1.21, 2), 2)), 2)} Kč`,
  ]);
  worksheet.addRow(["", "", "", "", "", "celkem s DPH:", "", `${separateThousands(usr.price)}.00 Kč`]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);

  worksheet.addRow(["List: 1"]);
  worksheet.addRow(["Počet listů: 1"]);
  worksheet.addRow(["Fakturu vystavil: Marek Chocholatý"]);
};

const setMerges = (worksheet: Excel.Worksheet) => {
  worksheet.mergeCells("A1:E1");
  worksheet.mergeCells("F1:H1");

  worksheet.mergeCells("A2:D2");
  worksheet.mergeCells("E2:H2");

  worksheet.mergeCells("A3:D3");
  worksheet.mergeCells("E3:H3");

  worksheet.mergeCells("A4:D4");
  worksheet.mergeCells("E4:H4");

  worksheet.mergeCells("A5:D5");
  worksheet.mergeCells("E5:H5");

  worksheet.mergeCells("A6:D6");
  worksheet.mergeCells("E6:H6");

  worksheet.mergeCells("A7:D7");
  worksheet.mergeCells("E7:H7");

  worksheet.mergeCells("A8:D8");
  worksheet.mergeCells("E8:H8");

  worksheet.mergeCells("A9:D9");
  worksheet.mergeCells("E9:H9");

  worksheet.mergeCells("A10:D10");
  worksheet.mergeCells("E10:H10");

  worksheet.mergeCells("A11:D12");

  worksheet.mergeCells("A13:D13");
  worksheet.mergeCells("E13:G13");

  worksheet.mergeCells("A14:D14");
  worksheet.mergeCells("E14:G14");

  worksheet.mergeCells("A15:D15");
  worksheet.mergeCells("E15:G15");

  worksheet.mergeCells("A16:C16");
  worksheet.mergeCells("E16:G16");

  worksheet.mergeCells("A17:C17");
  worksheet.mergeCells("E17:F17");
  worksheet.mergeCells("G17:H17");

  worksheet.mergeCells("A18:C18");
  worksheet.mergeCells("E18:G18");

  worksheet.mergeCells("A19:C19");
  worksheet.mergeCells("E19:G19");

  worksheet.mergeCells("A20:H20");
  worksheet.mergeCells("A21:H21");

  worksheet.mergeCells("A22:G22");
  worksheet.mergeCells("A23:G23");
  worksheet.mergeCells("A24:G24");
  worksheet.mergeCells("A25:G25");
  worksheet.mergeCells("A26:G26");
  worksheet.mergeCells("A27:G27");

  worksheet.mergeCells("A33:E33");
  worksheet.mergeCells("F33:G33");
  worksheet.mergeCells("F34:G34");
  worksheet.mergeCells("F35:G35");

  worksheet.mergeCells("A39:C39");
  worksheet.mergeCells("A40:C40");
  worksheet.mergeCells("A41:D41");
};

const setSizes = (worksheet: Excel.Worksheet) => {
  worksheet.getColumn("A").width = 99.32 / 7.5;
  worksheet.getColumn("B").width = 69.16 / 7.5;
  worksheet.getColumn("C").width = 51.68 / 7.5;
  worksheet.getColumn("D").width = 164.48 / 7.5;
  worksheet.getColumn("E").width = 98.4 / 7.5;
  worksheet.getColumn("F").width = 125.56 / 7.5;
  worksheet.getColumn("G").width = 42.08 / 7.5;
  worksheet.getColumn("H").width = 101.36 / 7.5;

  worksheet.getRow(1).height = 72 * 0.44;
  worksheet.getRow(12).height = 72 * 0.42;
  worksheet.getRow(23).height = 72 * 0.34;
  worksheet.getRow(29).height = 72 * 1.41;
};

const formatFonts = (worksheet: Excel.Worksheet) => {
  worksheet.getCell("A1").font = { name: "Calibri", bold: true, size: 16 };

  worksheet.getCell("F1").font = { bold: true, name: "Calibri", size: 16, color: { argb: "FFFF0000" } };

  worksheet.getCell("A3").font = { bold: true, name: "Calibri" };
  worksheet.getCell("E3").font = { bold: true, name: "Calibri" };

  worksheet.getCell("A11").font = { size: 8, name: "Calibri" };

  worksheet.getCell("A15").font = { bold: true, name: "Calibri" };
  worksheet.getCell("H15").font = { bold: true, name: "Calibri" };

  worksheet.getCell("D16").font = { bold: true, name: "Calibri" };
  worksheet.getCell("H16").font = { bold: true, name: "Calibri" };

  worksheet.getCell("D17").font = { bold: true, name: "Calibri" };
  worksheet.getCell("G17").font = { bold: true, name: "Calibri" };

  worksheet.getCell("D18").font = { bold: true, name: "Calibri" };
  worksheet.getCell("H18").font = { bold: true, name: "Calibri" };

  worksheet.getCell("A21").font = { bold: true, name: "Calibri" };

  worksheet.getCell("A22").font = { size: 8, name: "Calibri" };
  worksheet.getCell("H22").font = { size: 8, name: "Calibri" };

  worksheet.getCell("H23").font = { bold: true, name: "Calibri" };

  worksheet.getCell("H24").font = { bold: true, name: "Calibri" };

  worksheet.getCell("A26").font = { bold: true, name: "Calibri" };
  worksheet.getCell("H26").font = { bold: true, name: "Calibri" };

  worksheet.getCell("A33").font = { bold: true, name: "Calibri" };

  worksheet.getCell("A39").font = { bold: true, color: { argb: "FF0070C0" }, name: "Calibri" };
  worksheet.getCell("A40").font = { bold: true, color: { argb: "FF0070C0" }, name: "Calibri" };
  worksheet.getCell("A41").font = { bold: true, name: "Calibri" };
};

const setAlignment = (worksheet: Excel.Worksheet) => {
  worksheet.getCell("A1").alignment = { horizontal: "left", vertical: "middle" };
  worksheet.getCell("F1").alignment = { horizontal: "right", vertical: "middle" };

  worksheet.getCell("A11").alignment = { horizontal: "left", vertical: "middle", wrapText: true };

  worksheet.getCell("H15").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("H16").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("G17").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("H18").alignment = { horizontal: "right", vertical: "middle" };

  worksheet.getCell("H22").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("A23").alignment = { horizontal: "left", vertical: "middle", wrapText: true };
  worksheet.getCell("H23").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("H24").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("H26").alignment = { horizontal: "right", vertical: "middle" };

  worksheet.getCell("H33").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("H34").alignment = { horizontal: "right", vertical: "middle" };
  worksheet.getCell("H35").alignment = { horizontal: "right", vertical: "middle" };
};

const setBorders = (worksheet: Excel.Worksheet) => {
  worksheet.getCell("A1").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("F1").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };

  worksheet.getCell("A13").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("E13").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("H13").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };

  worksheet.getCell("A19").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("D19").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("E19").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("H19").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };

  worksheet.getCell("A27").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("H27").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };

  worksheet.getCell("A31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("B31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("C31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("D31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("E31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("F31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("G31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("H31").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };

  worksheet.getCell("A36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("B36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("C36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("D36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("E36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("F36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("G36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
  worksheet.getCell("H36").border = { bottom: { style: "thin", color: { argb: "FF000000" } } };
};

function toDataUrl(url: string, callback: (data: string | ArrayBuffer | null) => void) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}
