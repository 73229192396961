import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CheckCircle, Delete, Error, ExpandMore, Info, Payment, FileCopy } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useLayout } from "../../Layout/LayoutContext";
import { Courses, User } from "../../Types/courses";
import { api } from "../../Utils/ApiService";
import { generateInvoice } from "../../Utils/InvoiceGenerator";

export default function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [courses, setCourses] = useState<Courses | undefined>(undefined);
  const [openedChildRows, setOpenedChildRows] = useState<number[]>([]);
  const [search, setSearch] = useState("");
  const layout = useLayout();

  const searchFilter = (u: User) =>
    search.length === 0 ||
    (u.name?.toLowerCase() || "").includes(search.toLowerCase()) ||
    (u.surname?.toLowerCase() || "").includes(search.toLowerCase()) ||
    (u.mail?.toLowerCase() || "").includes(search.toLowerCase()) ||
    (u.variableSymbol?.toLowerCase() || "").includes(search.toLowerCase()) ||
    (u.course?.toLowerCase() || "").includes(search.toLowerCase());

  useEffect(() => {
    api.get(
      "getUsers",
      {},
      {
        success: setUsers,
        error: () => layout.error("Při načítání uživatelů došlo k chybě"),
      }
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    layout.setIsLoading(true);
    api.get(
      "/fileContent",
      { location: "config", file: "courses.json" },
      {
        success: (c) => {
          setCourses(JSON.parse(c));
          layout.setIsLoading(false);
        },
        error: () => layout.error("Při načítání kurzů došlo k chybě"),
      }
    );
    //eslint-disable-next-line
  }, []);

  const usersPerCourse: { [index: string]: User[] } = {};

  users
    .filter((u) => searchFilter(u))
    .forEach((u) =>
      (u.course || "") in usersPerCourse ? usersPerCourse[u.course || ""].push(u) : (usersPerCourse[u.course || ""] = [u])
    );

  return (
    <>
      <TextField fullWidth label="Vyhledat" value={search} onChange={(e) => setSearch(e.target.value)} />
      <br />
      <br />
      <br />
      <br />
      {Object.keys(usersPerCourse).map((k) => (
        <Accordion key={k}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              {k} ({usersPerCourse[k].length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Jméno</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Variabilní symbol</TableCell>
                  <TableCell>Zaplaceno</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersPerCourse[k].map((u) => (
                  <React.Fragment key={u.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            openedChildRows.includes(u.id)
                              ? setOpenedChildRows(openedChildRows.filter((i) => i !== u.id))
                              : setOpenedChildRows([...openedChildRows, u.id])
                          }
                          color="primary"
                        >
                          <Info />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {u.name} {u.surname}
                      </TableCell>
                      <TableCell>{u.mail}</TableCell>
                      <TableCell>{u.variableSymbol}</TableCell>
                      <TableCell>{u.paid ? <CheckCircle color="primary" /> : <Error color="error" />}</TableCell>
                      <TableCell>
                        <Tooltip title="Stáhnout fakturu">
                          <IconButton onClick={() => courses && generateInvoice(u, courses)}>
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Stáhnout proforma fakturu">
                          <IconButton
                            onClick={() =>
                              window.open("https://apipython.englishfit.cz/getTempInvoice?path=" + u.tempInvoice, "_blank")
                            }
                          >
                            <FileCopy style={{ color: "#1429c7" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Nastavit jako zaplaceno">
                          <IconButton
                            color={u.paid ? "secondary" : "primary"}
                            onClick={() =>
                              layout.confirm(
                                u.paid ? "Nastavit jako nezaplaceno" : "Nastavit jako zaplaceno",
                                u.paid
                                  ? "Opravdu chcete zrušit platbu u tohoto uživatele?"
                                  : "Opravdu chcete nastavi u tohoto uživatele,že zaplatil kurz?",
                                () =>
                                  api.post("setPaid", { userId: u.id, paid: !u.paid }, null, {
                                    success: () =>
                                      setUsers(users.map((uu) => (u.id === uu.id ? { ...uu, paid: !u.paid } : uu))),
                                    error: () => layout.error("Při ukládání došlo k chybě"),
                                  })
                              )
                            }
                          >
                            <Payment />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="smazat">
                          <IconButton
                            onClick={() =>
                              layout.deleteConfirm("Smazat uživatele", "Opravdu chcete smazat tohoto uživatele?", () =>
                                api.delete("deleteUser", { userId: u.id }, null, {
                                  success: () => setUsers(users.filter((uu) => u.id !== uu.id)),
                                  error: () => layout.error("Při mazání došlo k chybě"),
                                })
                              )
                            }
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    {openedChildRows.includes(u.id) && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <Typography variant="h6">Osobní údaje</Typography>
                              <Typography>
                                <b>Jméno</b>: {u.name} {u.surname}
                              </Typography>
                              <Typography>
                                <b>Email</b>: {u.mail}
                              </Typography>
                              <Typography>
                                <b>Telefon</b>: {u.phone}
                              </Typography>
                              <Typography>
                                <b>Adresa</b>: {u.street} {u.houseNumber}, {u.zip} {u.city}
                              </Typography>
                              {u.companyName && (
                                <Typography>
                                  <b>Společnost</b>: {u.companyName}
                                </Typography>
                              )}
                              {u.companyNumber && (
                                <Typography>
                                  <b>IČO</b>: {u.companyNumber}
                                </Typography>
                              )}
                              {u.taxId && (
                                <Typography>
                                  <b>DIČ</b>: {u.taxId}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant="h6">Platební údaje</Typography>
                              <Typography>
                                <b>Variabilní smybol</b>: {u.variableSymbol}
                              </Typography>
                              <Typography>
                                <b>Cena</b>: {u.price} Kč
                              </Typography>
                              <Typography>
                                <b>Zaplaceno</b>: {u.paid ? "ANO" : "NE"}
                              </Typography>
                              {u.differentBillingAddress && (
                                <>
                                  <Typography>
                                    <b>Fakturační adresa</b>: {u.billingStreet} {u.billingHouseNumber}, {u.billingZip}{" "}
                                    {u.billingCity}
                                  </Typography>
                                  {u.billingCompanyName && (
                                    <Typography>
                                      <b>Společnost</b>: {u.billingCompanyName}
                                    </Typography>
                                  )}
                                  {u.billingCompanyNumber && (
                                    <Typography>
                                      <b>IČO</b>: {u.billingCompanyNumber}
                                    </Typography>
                                  )}
                                  {u.billingTaxId && (
                                    <Typography>
                                      <b>DIČ</b>: {u.billingTaxId}
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant="h6">Doplňující informace</Typography>
                              <Typography>{u.additionalInfo}</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
